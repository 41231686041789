/* Generated by Font Squirrel (http://www.fontsquirrel.com) on June 22, 2015 */



@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url('proximanova-bold-webfont.eot');
    src: url('proximanova-bold-webfontd41dd41dd41d.eot?#iefix') format('embedded-opentype'),
         url('proximanova-bold-webfont.woff2') format('woff2'),
         url('proximanova-bold-webfont.woff') format('woff'),
         url('proximanova-bold-webfont.ttf') format('truetype'),
         url('proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'proxima_novaregular_italic';
    src: url('proximanova-regitalic-webfont.eot');
    src: url('proximanova-regitalic-webfontd41dd41dd41d.eot?#iefix') format('embedded-opentype'),
         url('proximanova-regitalic-webfont.woff2') format('woff2'),
         url('proximanova-regitalic-webfont.woff') format('woff'),
         url('proximanova-regitalic-webfont.ttf') format('truetype'),
         url('proximanova-regitalic-webfont.svg#proxima_novaregular_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('proximanova-regular-webfont.eot');
    src: url('proximanova-regular-webfontd41dd41dd41d.eot?#iefix') format('embedded-opentype'),
         url('proximanova-regular-webfont.woff2') format('woff2'),
         url('proximanova-regular-webfont.woff') format('woff'),
         url('proximanova-regular-webfont.ttf') format('truetype'),
         url('proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_novasemibold';
    src: url('proximanova-semibold-webfont.eot');
    src: url('proximanova-semibold-webfontd41dd41d.eot?#iefix') format('embedded-opentype'),
         url('proximanova-semibold-webfont.woff2') format('woff2'),
         url('proximanova-semibold-webfont.woff') format('woff'),
         url('proximanova-semibold-webfont.ttf') format('truetype'),
         url('proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}